<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑账户信息' : '新增账户信息'" append-to-body :close-on-click-modal="false" width="600px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" label-suffix=":" v-if="form">
      <el-form-item prop="accountType" label="账户类型">
        <el-select v-model="form.accountType" filterable placeholder="请选择账户类型" style="width:100%">
          <el-option v-for="(value,key) in accountTypes" :key="key" :label="value" :value="key" />
        </el-select>
      </el-form-item>
      <el-form-item prop="bankName" label="银行名称" v-if="form.accountType ===0">
        <el-input v-model.trim="form.bankName" :maxlength="50" clearable />
      </el-form-item>
      <el-form-item prop="accountName" label="账户" v-if="form.accountType ===0">
        <el-input v-model.trim="form.accountName" :maxlength="50" clearable />
      </el-form-item>
      <el-form-item prop="accountCode" label="银行卡号" v-if="form.accountType ===0">
        <el-input v-model.trim="form.accountCode" :maxlength="50" clearable />
      </el-form-item>
      <el-form-item prop="accountCode" label="支付宝账号" v-if="form.accountType ===1">
        <el-input v-model.trim="form.accountCode" :maxlength="50" clearable />
      </el-form-item>
      <el-form-item prop="accountCode" label="微信账号" v-if="form.accountType ===2">
        <el-input v-model.trim="form.accountCode" :maxlength="50" clearable />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/account";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      accountTypes: ["银行卡", "支付宝", "微信"],
      rules: {
        bankName: [{ required: true, message: "请填写银行名称" }],
        accountType: [{ required: true, message: "请选择账户类型" }],
        accountCode: [        
        { required: true, message: '请填写账号'},
        ],
        accountName: [{ required: true, message: "请填写账户" }],
      }
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then(res => {
              this.$notify({
                title: `保存账号信息成功`,
                type: "success",
                duration: 2500
              });
              this.$parent.init();
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form) {
      this.form = form || {
        accountCode: "",
        accountName: "",
        accountType: 0,
        bankName: "",
      };
      this.visible = true;
    }
  }
};
</script>